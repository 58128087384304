@import "./../../../../variables.scss";

.common-button {
  position: relative;
  padding: 5px 0;
  background: none;
  margin-right: 11px;
  &:before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 11px;
    height: 100%;
  }
  &:before {
    left: -10px;
    background: url("../../../../assets/buttons/left.png") center / cover no-repeat;
  }
  &::after {
    right: -11px;
    background: url("../../../../assets/buttons/right.png") center / cover no-repeat;
  }
  &_disabled {
    opacity: 0.3;
  }
  &__inner {
    width: 100%;
    height: 100%;
    padding: 11px 10px;
    background: $goldColor;
    &:before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      background: $goldColor;
    }
    &:before {
      top: 0;
    }
    &::after {
      bottom: 0;
    }
  }
  &__text {
    font-family: $fontfamilyBokor;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    text-transform: capitalize;
    color: $buttonBlack;
  }
}
