/* accordion */
.accordion { 
  &__trigger {
    cursor: pointer;
    user-select: none;
  }
  &__content {
    transition: all 0.3s;
    &:not(.open) {
      opacity: 0;
      height: 0;
      padding-top: 0;
      padding-bottom: 0;
    }  
  }
}
/* /accordion */