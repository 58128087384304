//fonts
$fontfamilyBokor: 'Bokor', cursive;
$fontfamilyOpenSans: 'Open Sans', sans-serif;
$fontfamilyOswalds: 'Oswald', sans-serif;
//colors
$bronzeColor: #cf8b4a;
$buttonBlack: #0C0A0A;
$goldColor: #FAB925;
$bronzeLightColor: #E7B16E;
$whiteColor: #ffffff;
$blackColor: #000000;
$menuLinkColor: #E9EEFF;

