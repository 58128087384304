@use '../../../variables.scss' as v;

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  &__image {
    max-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__address {
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    color: v.$whiteColor;
  }
}
