@use "../../../../../variables.scss" as v;

.tabs-switchers {
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: calc(100% - 20px);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
