/* cards */
.cards { 
  padding: 95px 0 140px;
  background-image: linear-gradient(rgba(12,13,19, 1) 5%, transparent, rgba(12,13,19, 1) 95%), url(../../img/cards/bg.png);
  background-size: cover;
  background-position: center;
  .subtitle {
    margin-bottom: 60px;
  }
  &__items {
    > .row {
      margin-top: -70px;
      > * {
        padding-top: 70px;
      }
    }
  }
  &__col {
    @include col(6);
    @include col-sm(12);
  }
  &__item {
    height: 100%;
    min-height: 527px;
    position: relative;
    &:before {
      content: '';
      display: block;
      border: 70px solid #000;
      border-image: url(../../img/border.png) 70 round round;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
    }
  }
  &__item-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 45px 50px 70px;
    position: relative;
    z-index: 3;
  }
  &__title {
    margin-top: auto;
    font-family: 'Bokor', Arial, sans-serif;
    font-weight: 400;
    font-size: 41px;
    line-height: 46px;
    margin-bottom: 25px;
  }
  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    p {
      margin-bottom: 20px;
    }
  }
  &__btn {
    position: absolute !important;
    bottom: -30px;
    left: 50%;
    transform: translate(-50%,0);
  }
  &__img {
    padding: 15px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    &:before {
      content: '';
      display: block;
      height: 70%;
      background: linear-gradient(to top, #000 10%, transparent);
      position: absolute;
      bottom: 15px;
      left: 15px;
      right: 15px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }
  }
  &__values {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 15px;
  }
  &__value {
    margin: 0 20px;
    font-family: 'Oswald', Arial, sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    span {
      margin-right: 10px;
      color: $bronze_light;
    }
  }
  &__lock {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    margin-bottom: 30px;
    img {
      flex-shrink: 0;
      margin-right: 10px;
    }
    span {
      margin-right: 5px;
    }
  }
  @media (max-width: $break_sm) {
    padding: 120px 0 65px;
    background-image: url(../../img/cards/bg-mob.png);
    background-size: 100% auto;
    background-position: top center;
    background-repeat: no-repeat;
    .subtitle {
      margin-bottom: 36px;
    }
    &__title {
      font-size: 36px;
      line-height: 46px;
      text-align: center;
      margin-bottom: 20px;
    }
    &__item {
      min-height: 585px;
    }
    &__item-content {
      padding: 30px 35px 40px;
    }
    &__values {
      display: none;
    }
    &__btn {
      bottom: -21px;
      width: 178px;
      height: 64px;
      font-size: 17px;
      line-height: 20px;
    }
  }
}
/* /cards */