@use '../../../../../variables.scss' as v;

.locked-info {
  display: flex;
  align-items: center;
  gap: 5px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 60px;
  &__img {
    width: 20px;
    height: 20px;
    object-fit: cover;
  }

  &__text {
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    color: v.$whiteColor;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 3px;
  }
}
