@use '../../../variables.scss' as v;

.notification {
  width: 544px;
  position: fixed;
  right: 10px;
  top: 170px;
  z-index: 10;
  display: none;
  &.active {
    display: block;
  }
  &__exit {
    content: url('../../../assets/icons/exit.svg');
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 6;
    cursor: pointer;
  }
  &__inner {
    display: flex;
    align-items: center;
    gap: 10.5px;
    background: #0c0d13;
    padding: 27px 20px;
    position: relative;
    width: 100%;
    height: 100%;
  }
  &__content {
    width: 100%;
  }
  &__icon {
    &-image {
      width: 48.27px;
      height: 49.86px;
      object-fit: cover;
    }
  }
  &__title {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: #ffffff;
  }
  &__text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }
  @media (max-width: 830px) {
    max-width: 390px;
    width: 100%;
    right: 0;
  }
}
