@import './../../../variables.scss';
.staking-page .header {
  position: absolute;
  left: 0;
  width: 100%;
  top: 40px;
  z-index: 3;
  padding: 0 40px;
  &__inner {
    background: $blackColor;
    border: 2px solid $bronzeColor;
    max-width: 1370px;
    margin: 0 auto;
    padding: 16px 20px;
    display: flex;
    align-items: center;
    gap: 23.5px;
    position: relative;
    &::before,
    &::after {
      content: '';
      width: 31px;
      height: 95px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    &::before {
      left: -30.5px;
      background: url('../../../assets/header/left.png') center / cover
        no-repeat;
    }
    &::after {
      right: -30.5px;
      background: url('../../../assets/header/right.png') center / cover
        no-repeat;
    }
  }
  &__top-img,
  &__bottom-img {
    position: absolute;
    width: 278px;
    height: 29px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__top-img {
    top: 0;
    background: url('../../../assets/header/middle-top.png') center / cover
      no-repeat;
    transform: translate(-50%, -100%);
  }

  &__bottom-img {
    bottom: 0;
    background: url('../../../assets/header/middle-bottom.png') center / cover
      no-repeat;
    transform: translate(-50%, 100%);
  }
  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__launch {
    font-family: $fontfamilyBokor;
    font-weight: 400;
    font-size: 22px;
    line-height: 39px;
    text-transform: capitalize;
    color: $menuLinkColor;
  }
  .avatar {
    height: 42px;
    cursor: pointer;
  }

  @media (max-width: 600px) {
    padding: 0 15px;
    &__inner {
      gap: 10px;
      padding: 11px 18px;
      &::before,
      &::after {
        display: none;
      }
    }
    &__content {
      gap: 10px;
    }
  }
  @media (max-width: 570px) {
    &__launch {
      font-size: 15px;
    }
    .avatar__address {
      display: none;
    }
  }
}
