@import '../../../variables.scss';

.percent-rate {
  display: flex;
  align-items: center;
  gap: 40px;
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  &__item {
    font-family: $fontfamilyOswalds;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  &__title {
    color: $bronzeLightColor;
  }
  &__number {
    color: $whiteColor;
    display: flex;
    align-items: center;
    gap: 3px;
  }
}
