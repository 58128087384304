/* header */
.header {
  padding: 52px 0;
  .wrapper {
    max-width: 1395px;
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 22px;
    position: relative;
    border: 2px solid $bronze_dark;
    background: #000000;
    &:before {
      content: '';
      display: block;
      width: 31px;
      height: 95px;
      background-image: url(../../img/header/left.png);
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      right: 100%;
      transform: translate(0, -50%);
    }
    &:after {
      content: '';
      display: block;
      width: 31px;
      height: 95px;
      background-image: url(../../img/header/right.png);
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translate(0, -50%);
    }
  }
  &__img-top {
    width: 278px;
    height: 29px;
    background-image: url(../../img/header/middle-top.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 0);
  }
  &__img-bottom {
    width: 278px;
    height: 29px;
    background-image: url(../../img/header/middle-bottom.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
  }
  &__nav {
    padding-right: 20px;
  }
  &__logo {
    flex-shrink: 0;
    display: block;
    max-width: 160px;
    margin-right: 50px;
    width: 155px;
    height: 27px;
    img {
      object-fit: cover;
      width: 100%;
    }
  }
  &__menu {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-family: 'Bokor', Arial, sans-serif;
    font-size: 22px;
    line-height: 39px;
    margin-left: -30px;
    li {
      margin-left: 30px;
      a {
        font-family: 'Bokor';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 39px;
        color: #e9eeff;
        &:hover {
          color: $bronze_light;
        }
      }
    }
  }
  &__btn {
    flex-shrink: 0;
    width: 145px;
    height: 52px;
    padding: 0 10px;
    font-family: 'Bokor', Arial, sans-serif;
    font-size: 17px;
    line-height: 20px;
    color: $btnText;
    text-align: center;
    position: relative;
    outline: none;
    border: none;
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0 0 30px rgba(250, 185, 37, 0.5);
      border-radius: 50px;
      filter: blur(10%);
      opacity: 0;
      transition: 0.3s;
    }
    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }
  &__btn-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../../img/action-btn.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
  &__hamburger {
    display: none;
    border: 1px solid #191919;
    border-radius: 5px;
    .hamburger-box {
      display: block;
    }
  }
  &__menu-close {
    display: none;
  }
  @media (max-width: $break_sm) {
    padding: 40px 0;
    .wrapper {
      padding-left: 15px;
      padding-right: 15px;
    }
    &__container {
      padding: 10px 18px;
      &:after,
      &:before {
        display: none;
      }
    }
    &__hamburger {
      display: block;
    }
    &__btn {
      display: none;
    }
    &__nav {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 400px;
      padding: 80px 20px 30px;
      background-color: #0c0d13;
      box-shadow: 20px 0 20px rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      bottom: 0;
      left: -800px;
      transition: 0.5s;
      z-index: 10;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        border: 2px solid $bronze_dark;
        z-index: -1;
      }
      &.open {
        left: 0;
      }
    }
    &__menu {
      flex-grow: 1;
      display: block;
      font-size: 30px;
      line-height: 56px;
      overflow-y: auto;
      li {
        a {
          display: block;
          color: $bronze_light;
        }
        .header__btn {
          display: block;
          color: $btnText;
        }
        &:last-child {
          margin-top: 15px;
        }
      }
    }
  }
  @media (max-width: $break_xs) {
    &__nav {
      max-width: none;
    }
    &__menu-close {
      display: block;
      width: 25px;
      height: 25px;
      position: absolute;
      top: 20px;
      right: 20px;
      &:after,
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
      }
      &:after {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:before {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}
