@import url('https://fonts.googleapis.com/css2?family=Bokor&family=Open+Sans:wght@300;400;600;700&family=Oswald:wght@300;400;500;600&display=swap');
@import './variables.scss';

* {
  padding: 0;
  margin: 0;
  border: 0;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:focus,
:active {
  outline: none;
}
a:focus,
a:active {
  outline: none;
}
nav,
footer,
header,
aside,
section {
  display: block;
}
html,
body {
  height: 100%;
  width: 100%;
}
body {
  font-family: $fontfamilyOpenSans;
  font-style: normal;
  font-weight: normal;
  color: #ffffff;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  background: #000000;
}
input,
button,
textarea {
  font-family: $fontfamilyOpenSans;
}
input::-ms-clear {
  display: none;
}
button {
  cursor: pointer;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a,
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  font-family: $fontfamilyOpenSans;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  min-width: 319px;
  background-color: #0c0d13;
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 16px;
  &.lock {
    overflow: hidden;
  }
}
.wrapper.staking-page {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}
