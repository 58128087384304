/* faq */
.faq { 
  padding-bottom: 174px;
  .subtitle {
    margin-bottom: 40px;
  }
  &__accordion {}
  &__item {
    margin-bottom: 5px;
  }
  &__trigger {
    padding: 23px 40px 23px 15px;
    background: rgba(0, 0, 0, 0.5);
    font-weight: 700;
    font-size: 23px;
    line-height: 26px;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 10px;
      height: 100%;
      background: 
        linear-gradient(to left top, rgba(0, 0, 0, 0.5) 49%, transparent 51%) top / 100% 50% no-repeat, 
        linear-gradient(to left bottom, rgba(0, 0, 0, 0.5) 49%, transparent 51%) bottom / 100% 50% no-repeat;
      position: absolute;
      right: 100%;
      top: 0;
    }
    &:after {
      content: '';
      display: block;
      width: 10px;
      height: 100%;
      background: 
        linear-gradient(to right top, rgba(0, 0, 0, 0.5) 49%, transparent 51%) top / 100% 50% no-repeat, 
        linear-gradient(to right bottom, rgba(0, 0, 0, 0.5) 49%, transparent 51%) bottom / 100% 50% no-repeat;
      position: absolute;
      left: 100%;
      top: 0;
    }
    &.active {
      background: rgba(198, 134, 75, 0.2);
      &:before {
        background: 
          linear-gradient(to left top, rgba(198, 134, 75, 0.2) 49%, transparent 51%) top / 100% 50% no-repeat, 
          linear-gradient(to left bottom, rgba(198, 134, 75, 0.2) 49%, transparent 51%) bottom / 100% 50% no-repeat;
      }
      &:after {
        background: 
          linear-gradient(to right top, rgba(198, 134, 75, 0.2) 49%, transparent 51%) top / 100% 50% no-repeat, 
          linear-gradient(to right bottom, rgba(198, 134, 75, 0.2) 49%, transparent 51%) bottom / 100% 50% no-repeat;
      }
      .faq__arrow {
        transform: translate(0, -50%) rotate(180deg);
      }
    }
  }
  &__arrow {
    position: absolute;
    top: 50%;
    right: 19px;
    transition: .3s;
    transform: translate(0,-50%);
  }
  &__content {
    padding: 23px 15px;
    margin-top: 4px;
    background: rgba(198, 134, 75, 0.2);
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    color: $grayTexts;
  }
  @media (max-width: $break_md) {
    padding-bottom: 100px;
  }
  @media (max-width: $break_sm) {
    &__trigger {
      display: flex;
      align-items: center;
      min-height: 78px;
      padding: 15px 40px 15px 15px;
      font-size: 18px;
      line-height: 24px;
      &:after {
        display: none;
      }
      &:before {
        display: none;
      }
    }
    &__content {
      padding: 10px;
      font-size: 16px;
      line-height: 24px;
    }
  }
  @media (max-width: $break_xs) {
    padding-bottom: 212px;
  }
}
/* /faq */