/* social */
.social { 
  padding-top: 100px;
  &__title {
    font-family: "Bokor", Arial, sans-serif;
    font-weight: 400;
    font-size: 52px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 50px;
  }
  &__icons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: -10px;
    margin-left: -10px;
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 100%;
    background-color: $gold;
    img {
      max-width: 21px;
    }
  }
  @media (max-width: $break_sm) {
    &__title {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 30px;
    }
  }
}
/* /social */