@use "../../../../variables.scss" as v;

.tabs {
  &__inner {
    max-width: 1155px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}

.without-staking-title {
  margin-top: 80px;
  text-align: center;
  font-family: v.$fontfamilyBokor;
  font-weight: 400;
  font-size: 35px;
  line-height: 40px;
  color: v.$whiteColor;
}
