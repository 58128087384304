.staking-warning {
  width: 100%;
  padding: 0 20px;
  position: relative;
  &::before,
  &::after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border: 0 solid transparent;
    top: 50%;
    transform: translateY(-50%);
  }
  &::before {
    left: 0;
    border-top-width: calc(35px / 2);
    border-bottom-width: calc(35px / 2);
    border-right: 20px solid rgba(255, 255, 255, 0.1);
  }
  &::after {
    right: 0;
    border-bottom-width: calc(35px / 2);
    border-top-width: calc(35px / 2);
    border-left: 20px solid rgba(255, 255, 255, 0.1);
  }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background: rgba(255, 255, 255, 0.1);
    padding: 7.5px 0;
    height: 35px;
  }
  &__img {
    max-width: 100%;
    object-fit: cover;
    path {
      background-color: red;
    }
  }
  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(#ffffff, 0.7);
  }
}
