@use '../../../../variables.scss' as variables;
.staking-title {
  font-family: variables.$fontfamilyBokor;
  font-weight: 400;
  font-size: 41px;
  line-height: 46px;
  color: variables.$whiteColor;
  @media (max-width: 550px) {
    font-size: 36px;
    text-align: center;
  }
}
