@use '../../../../../../variables.scss' as v;

.tabs-switcher-item {
  font-family: v.$fontfamilyBokor;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  color: v.$bronzeColor;
  padding: 10px;
  border-bottom: 1px solid v.$bronzeLightColor;
  &_disabled {
    color: rgba(v.$bronzeLightColor, 0.4);
    border-color: rgba(v.$bronzeLightColor, 0.4);
    cursor: pointer;
  }
  @media (max-width: 374px) {
    font-size: 23px;
    line-height: 28px;
  }
}
