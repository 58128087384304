@import 'mixins/mixins';

// @include reset(); // Сброс стилей

@import 'mixingrid/mixingrid';

// Переменные для кастомизации Mixingrid
$columns: 12; // Колличество колонок
$max-width: 1115px; // Максимальная ширина контейнера
$gutter: 25px; // Расстояние между колонками
$offset: 30px; // Поля контейнера
$offset-sm: 20px; // Поля контейнера на мобилке
// Брэйкпоинты
$break_xl: 1400px;
$break_lg: 1200px;
$break_md: 1050px;
$break_sm: 768px;
$break_xs: 500px;

// Шрифты
/* open-sans-300 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/open-sans/open-sans-v34-latin-300.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/open-sans/open-sans-v34-latin-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans/open-sans-v34-latin-300.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/open-sans/open-sans-v34-latin-300.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/open-sans/open-sans-v34-latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/open-sans/open-sans-v34-latin-300.svg#OpenSans')
      format('svg');
  /* Legacy iOS */
}

/* open-sans-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans/open-sans-v34-latin-regular.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/open-sans/open-sans-v34-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans/open-sans-v34-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/open-sans/open-sans-v34-latin-regular.woff') format('woff'),
    /* Modern Browsers */
      url('../fonts/open-sans/open-sans-v34-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/open-sans/open-sans-v34-latin-regular.svg#OpenSans')
      format('svg');
  /* Legacy iOS */
}

/* open-sans-600 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/open-sans/open-sans-v34-latin-600.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/open-sans/open-sans-v34-latin-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans/open-sans-v34-latin-600.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/open-sans/open-sans-v34-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/open-sans/open-sans-v34-latin-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/open-sans/open-sans-v34-latin-600.svg#OpenSans')
      format('svg');
  /* Legacy iOS */
}

/* open-sans-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/open-sans/open-sans-v34-latin-700.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/open-sans/open-sans-v34-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans/open-sans-v34-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/open-sans/open-sans-v34-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/open-sans/open-sans-v34-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/open-sans/open-sans-v34-latin-700.svg#OpenSans')
      format('svg');
  /* Legacy iOS */
}

/* oswald-200 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/oswald/oswald-v49-latin-200.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/oswald/oswald-v49-latin-200.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/oswald/oswald-v49-latin-200.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('../fonts/oswald/oswald-v49-latin-200.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/oswald/oswald-v49-latin-200.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/oswald/oswald-v49-latin-200.svg#Oswald') format('svg');
  /* Legacy iOS */
}

/* oswald-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/oswald/oswald-v49-latin-regular.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/oswald/oswald-v49-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/oswald/oswald-v49-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/oswald/oswald-v49-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/oswald/oswald-v49-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/oswald/oswald-v49-latin-regular.svg#Oswald') format('svg');
  /* Legacy iOS */
}

/* oswald-500 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/oswald/oswald-v49-latin-500.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/oswald/oswald-v49-latin-500.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/oswald/oswald-v49-latin-500.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('../fonts/oswald/oswald-v49-latin-500.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/oswald/oswald-v49-latin-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/oswald/oswald-v49-latin-500.svg#Oswald') format('svg');
  /* Legacy iOS */
}

/* oswald-600 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/oswald/oswald-v49-latin-600.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/oswald/oswald-v49-latin-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/oswald/oswald-v49-latin-600.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('../fonts/oswald/oswald-v49-latin-600.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/oswald/oswald-v49-latin-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/oswald/oswald-v49-latin-600.svg#Oswald') format('svg');
  /* Legacy iOS */
}

/* bokor-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Bokor';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/bokor/bokor-v30-latin-regular.eot');
  /* IE9 Compat Modes */
  src: url('../fonts/bokor/bokor-v30-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/bokor/bokor-v30-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/bokor/bokor-v30-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/bokor/bokor-v30-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/bokor/bokor-v30-latin-regular.svg#Bokor') format('svg');
  /* Legacy iOS */
}

// Цвета
$white: #fff;
$gold: #fab925;
$bronze_light: #e7b16e;
$bronze_dark: #cf8b4a;
$grayTexts: #cecece;
$btnText: #0c0a0a;

.landing {
  // Подключение файлов
  @import 'blocks/settings';
  @import 'blocks/title';
  @import 'blocks/subtitle';
  @import 'blocks/btn';
  @import 'hamburgers/hamburgers';
  @import 'blocks/accordion';
  @import 'blocks/header';
  // @import 'blocks/welcome';
  @import 'blocks/staking';
  @import 'blocks/cards';
  @import 'blocks/requirements';
  @import 'blocks/faq';
  @import 'blocks/action';
  @import 'blocks/social';
  @import 'blocks/footer';
}
