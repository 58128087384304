@use "../../../../../../variables.scss" as v;

.subtable {
  max-width: calc(100% - 20px);
  margin-left: auto;
  margin-right: auto;
  margin-top: 4px;
  width: 100%;
  &__inner {
  }

  &__head {
    background: rgba(198, 134, 75, 0.2);
    opacity: 0.5;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 15px;
  }

  &__item {
    max-width: 200px;
    width: 100%;
    font-family: v.$fontfamilyOswalds;
    font-weight: 300;
    font-size: 17px;
    line-height: 26px;
    color: v.$whiteColor;
  }
}
