@use "../../../../../../../variables.scss" as v;

.subrow {
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(198, 134, 75, 0.2);
    padding: 10px 5px 10px 15px;
  }
  &__content {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  &__percent {
    font-family: v.$fontfamilyOswalds;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: v.$whiteColor;
  }
  &__buttons {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 290px;
    width: 100%;
    .action-button {
      width: 100%;
      max-width: 140px;
    }
  }
}
