@use '../../../../variables.scss' as v;

.popup-title {
  font-family: v.$fontfamilyBokor;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: v.$whiteColor;
}

.staking-content-buttons-percent {
  width: 93.75px;
  height: 52px;
  border: 2px solid v.$bronzeColor;
  position: relative;
  $percent: &;
  &__item {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);
    font-family: v.$fontfamilyBokor;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    text-transform: capitalize;
    color: v.$bronzeColor;
    cursor: pointer;
    &.active {
      cursor: default;
      color: v.$buttonBlack;
      &::before {
        content: '';
        background-color: v.$bronzeColor;
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }
    }
  }
  &:first-child,
  &:last-child {
    border: none;
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: url('../../../../assets/buttons/side-btn.svg') center / cover
        no-repeat;
    }
    #{$percent}__item {
      &::before {
        background: none;
      }
    }
    &.active {
      &::before {
        background: url('../../../../assets/buttons/side-btn-active.svg') center /
          cover no-repeat;
      }
    }
  }
  &:last-child {
    &::before {
      transform: rotate(-180deg);
    }
  }
  @media (max-width: 600px) {
    &:not(:first-child, :last-child) {
      width: 92px;
    }
  }
}

.staking-textarea {
  padding: 0 11px;
  position: relative;
  height: 52px;
  margin-bottom: 25px;
  width: 100%;
  &::before,
  &::after {
    content: url('../../../../assets/buttons/left-bronze.svg');
    height: 52px;
    width: 11px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  &::before {
    left: 0px;
  }
  &::after {
    right: 0px;
    transform: translateY(-50%) rotate(-180deg);
  }
  &__wrapper {
    width: 100%;
  }
  &__inner {
    border-top: 2px solid v.$bronzeColor;
    border-bottom: 2px solid v.$bronzeColor;
    width: 100%;
    height: 100%;
    padding: 0 5px;
  }

  &__input {
    background: none;
    font-weight: 700;
    font-size: 17px;
    line-height: 26px;
    color: v.$whiteColor;
    height: 100%;
    &::placeholder {
      font-weight: 700;
      font-size: 17px;
      line-height: 26px;
      color: v.$whiteColor;
    }
  }

  &__fee {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: v.$whiteColor;
  }
}

.popup-info {
  position: relative;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    border: 14px solid #000;
    border-image: url('../../../../assets/borders/success-border.svg') 14 round
      round;
  }
  &__text {
    padding: 10px;
    background: #211f1f;
    font-family: v.$fontfamilyOswalds;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: v.$bronzeLightColor;
  }
}
