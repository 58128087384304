@use '../../../../../../../variables.scss' as v;

.staking-card-content {
  position: relative;
  z-index: 33;
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__paragraf {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: v.$whiteColor;
  }
}
