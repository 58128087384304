@use '../../../../variables.scss' as v;

.wallet-content {
  padding: 51px 105px;
  background: #0c0d13;
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    position: relative;
    z-index: 3;
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }

  &__avatar {
  }

  &__address {
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    color: v.$whiteColor;
  }
  &__buttons {
    max-width: 313px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
    .action-button {
      width: 149px;
    }
  }
  @media (max-width: 600px) {
    padding: 33px 19px 28px 19px;
    border-radius: 20px 20px 0px 0px;
    .popup-title {
      font-size: 30px;
      line-height: 30px;
      display: flex;
      justify-content: flex-start;
      width: 100%;
    }
  }
}

.connect-wallet-content {
  width: 514px;
  height: 305px;
  background: #0c0d13;
  display: flex;
  align-items: center;
  justify-content: center;
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    min-width: 390px;
    position: relative;
    z-index: 3;
  }
  &__square {
    width: 70px;
    height: 70px;
  }

  &__title {
    font-family: v.$fontfamilyBokor;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: v.$whiteColor;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: v.$whiteColor;
  }
  &__link {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: v.$goldColor;
    padding-right: 25px;
    position: relative;
    &::after {
      content: url('../../../../assets/icons/arrow-top-right.svg');
      position: absolute;
      right: 0;
      top: 50%;
      height: 20px;
      width: 20px;
      transform: translateY(-50%);
    }
  }
}

.staking-content {
  padding: 47px 62px 79px 62px;
  background: #0c0d13;
  $stakingContent: &;
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 3;
  }
  &.locked {
    .popup-title {
      margin-bottom: 9.5px;
    }
    .staking-warning {
      margin-top: 20px;
      max-width: 100%;
    }
    .staking-textarea {
      flex-grow: 1;
      // max-width: 272px;
    }
  }
  &.unlocked {
    .popup-title {
      margin-bottom: 39px;
    }
  }
  &__content {
    display: flex;
    gap: 5px;
    width: 100%;
  }

  &__lock {
    border: 2px solid v.$goldColor;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px;
  }

  &__lock-input {
    font-weight: 700;
    font-size: 17px;
    line-height: 26px;
    color: v.$whiteColor;
    background: none;
    width: 100%;
  }
  &__item {
    width: 100%;
  }
  &__head {
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    color: v.$whiteColor;
    margin-bottom: 5px;
  }

  &__text {
    max-width: 390px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: v.$whiteColor;
    text-align: center;
  }

  &__balance {
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    margin-bottom: 25px;
    color: v.$whiteColor;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 390px;
  }

  &__title {
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    color: v.$whiteColor;
    margin-bottom: 5px;
  }
  &-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    $stakingContentButton: &;
  }
  @media (max-width: 600px) {
    padding: 33px 19px 28px 19px;
    border-radius: 20px 20px 0px 0px;
    &__inner {
      margin-bottom: 30px;
    }
    .common-button {
      width: 100%;
    }
    .popup-title {
      font-size: 30px;
      line-height: 30px;
      width: 100%;
      text-align-last: left;
    }
    &.unlocked .popup-title {
      margin-bottom: 35px;
    }
    &-buttons {
      gap: 0;
    }
  }
}

.reinvest-content {
  padding: 52px 65px 78px 65px;
  background: #0c0d13;
  &__inner {
    position: relative;
    z-index: 3;
  }
  .popup-title {
    text-align: center;
    margin-bottom: 50px;
  }

  &__body {
    display: flex;
    width: 390px;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }
  &-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &__head {
      display: flex;
      flex-direction: column;
    }

    &__title {
      font-family: v.$fontfamilyOswalds;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: v.$bronzeLightColor;
    }

    &__money {
      font-family: v.$fontfamilyOswalds;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: v.$whiteColor;
      display: none;
    }

    &__amount {
      font-family: v.$fontfamilyBokor;
      font-weight: 400;
      font-size: 36px;
      line-height: 46px;
      color: v.$whiteColor;
    }
  }
  @media (max-width: 600px) {
    border-radius: 20px 20px 0px 0px;
    padding: 33px 19px 28px 19px;
    .common-button {
      width: 100%;
    }
    &__inner {
      margin-bottom: 30px;
    }
    &__body {
      width: 100%;
    }
    .popup-title {
      font-size: 30px;
      line-height: 30px;
      width: 100%;
      text-align-last: left;
      margin-bottom: 30px;
    }
  }
}

.unstake-content {
  padding: 45px 65px 90px 65px;
  width: 100%;
  background: #0c0d13;
  .popup-title {
    margin-bottom: 50px;
    text-align: center;
  }
  &__inner {
    position: relative;
    z-index: 3;
  }
  &-avliable {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    margin-bottom: 25px;
    &__title {
      font-weight: 400;
      font-size: 17px;
      line-height: 26px;
      color: rgba(v.$whiteColor, 0.6);
    }
    &__balance {
      font-weight: 700;
      font-size: 17px;
      line-height: 26px;
      color: v.$whiteColor;
    }
  }

  &__body {
  }
  @media (max-width: 600px) {
    border-radius: 20px 20px 0px 0px;
    padding: 33px 10px 28px 10px;
    .popup-title {
      font-size: 29px;
      line-height: 30px;
      width: 100%;
      text-align-last: left;
      margin-bottom: 30px;
    }
    &__inner {
      margin-bottom: 30px;
    }
    .common-button {
      width: calc(100% - 22px);
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
