@use '../../../../variables.scss' as v;
/* welcome */
@use 'sass:math';
$columns: 12; // Колличество колонок
$max-width: 1200px; // Максимальная ширина контейнера
$gutter: 20px; // Расстояние между колонками
$offset: 30px; // Поля контейнера
$offset-sm: 15px; // Поля контейнера на мобилке (когда ширина экрана меньше ширины $break_sm)
// Брэйкпоинты
$break_xl: 1400px;
$break_lg: 1200px;
$break_md: 1050px;
$break_sm: 768px;
$break_xs: 500px;

@mixin col($n) {
  width: math.div(100%, 12) * $n;
}
@mixin col-sm($n) {
  @include sm(width, math.div(100%, 12) * $n);
}
@mixin sm($name, $value) {
  @media screen and (max-width: $break_sm) {
    #{$name}: $value;
  }
}
@mixin row() {
  display: flex;
  flex-wrap: wrap;
  margin-left: $gutter * -1;
  > * {
    word-wrap: break-word;
    box-sizing: border-box;
    padding-left: $gutter;
  }
}

@mixin wrapper() {
  width: 100%;
  max-width: $max-width + $offset * 2;
  padding-left: $offset;
  padding-right: $offset;
  margin-left: auto;
  margin-right: auto;
  @include sm(padding-left, $offset-sm);
  @include sm(padding-right, $offset-sm);
}
.welcome {
  padding: 25px 0 130px;
  .wrapper {
    @include wrapper();
    max-width: 1175px;
  }
  .row {
    @include row();
  }
  &__bg {
    background-image: linear-gradient(
        rgba(12, 13, 19, 0.8) 95%,
        rgba(12, 13, 19, 1)
      ),
      url(../../landing/img/welcome-bg.png);
    background-size: cover;
    background-position: center;
  }
  .title {
    margin-bottom: 40px;
  }
  &__text {
    max-width: 733px;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #cecece;
    text-align: center;
    margin-bottom: 70px;
  }
  &__items {
    > .row {
      margin-top: -40px;
      > * {
        padding-top: 40px;
      }
    }
  }
  &__item1 {
    display: flex;
    flex-direction: column;
    @include col(4);
    @include col-sm(12);
  }
  &__item2 {
    display: flex;
    flex-direction: column;
    @include col(8);
    @include col-sm(12);
  }
  &__item-title {
    font-family: 'Bokor', Arial, sans-serif;
    font-weight: 400;
    font-size: 41px;
    line-height: 46px;
    text-align: center;
    margin-bottom: 30px;
  }
  &__block {
    display: flex;
    align-items: center;
    min-height: 210px;
    padding: 30px 40px;
    margin-top: auto;
    position: relative;
    &:before {
      content: '';
      display: block;
      border: 70px solid #000;
      border-image: url(../../landing/img/border.png) 70 round round;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  &__block-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: -20px;
    position: relative;
    z-index: 2;
    justify-content: space-evenly;
    gap: 20px;
    height: 100%;
  }
  &__item {
    // margin: 0 27px;
    // margin-top: 20px;
    text-align: center;
  }
  &__subtitle {
    font-family: 'Oswald', Arial, sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: v.$bronzeLightColor;
    margin-bottom: 15px;
  }
  &__num {
    font-family: 'Oswald', Arial, sans-serif;
    font-weight: 600;
    font-size: 30px;
    line-height: 32px;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
  }
  @media (max-width: $break_sm) {
    padding: 44px 0 120px;
    &__block {
      min-height: 1px;
      padding: 48px 40px;
    }
    &__item-title {
      font-size: 32px;
      line-height: 40px;
    }
    &__subtitle {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 8px;
    }
    &__num {
      font-size: 25px;
      line-height: 30px;
    }
    &__block-content {
      margin-top: -35px;
      height: auto;
    }
    &__item {
      margin-top: 35px;
    }
  }
}
/* /welcome */
