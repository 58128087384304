.loader {
  position: fixed;
  z-index: 10;
  height: 100vh;
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  background: rgba(#000000, 0.5);
  &_active {
    display: flex;
  }
}
