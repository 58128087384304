/* action */
.action { 
  // min-height: 467px;
  padding: 148px 0;
  border-top: 2px solid $bronze_light;
  border-bottom: 2px solid $bronze_light;
  background-image: url(../../img/action-bg.jpg);
  background-size: cover;
  background-position: top center;
  position: relative;
  text-align: center;
  &__left-img {
    max-width: 520px;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  &__right-img {
    max-width: 480px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &__title {
    font-family: 'Bokor', Arial, sans-serif;
    font-weight: 400;
    font-size: 52px;
    line-height: 50px;
    margin-bottom: 40px;
  }
  &__btn {
    
  }
  @media (max-width: $break_md) {
    &__left-img {
      max-width: 280px;
    }
    &__right-img {
      max-width: 250px;
    }
  }
  @media (max-width: $break_sm) {
    min-height: 286px;
    padding: 50px 0;
    &__left-img {
      max-width: 185px;
    }
    &__right-img {
      max-width: 170px;
    }
    &__title {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 30px;
    }
  }
  @media (max-width: $break_xs) {
    .wrapper {
      position: absolute;
      top: -127px;
      left: 0;
    }
  }
}
/* /action */