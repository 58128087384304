@use '../../../../../../variables.scss' as v;
$activeColor: rgba(198, 134, 75, 0.2);
$commonColor: rgba(0, 0, 0, 0.5);
.table-row {
  $tableRow: &;
  .subtable {
    display: none;
  }
  &__inner {
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &-item {
    padding: 0 10px;
    height: 72px;
    position: relative;

    &::before,
    &::after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      border: 0 solid transparent;
      top: 50%;
      transform: translateY(-50%);
    }
    &::before {
      border-top-width: calc(72px / 2);
      border-bottom-width: calc(72px / 2);
      border-right: 10px solid $commonColor;
      left: 0;
    }
    &::after {
      border-bottom-width: calc(72px / 2);
      border-top-width: calc(72px / 2);
      border-left: 10px solid $commonColor;
      right: 0;
    }
    &__inner {
      padding: 10px 20px 10px 15px;
      display: flex;
      align-items: center;
      background: $commonColor;
      justify-content: space-between;
      height: 100%;
    }

    &__content {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-grow: 1;
    }

    &__date {
      font-family: v.$fontfamilyOswalds;
      font-weight: 500;
      font-size: 17px;
      line-height: 26px;
      color: v.$whiteColor;
      max-width: 200px;
      width: 100%;
    }

    &__amount {
      display: flex;
      align-items: center;
      gap: 10px;
      max-width: 200px;
      width: 100%;
      &-number {
        font-family: v.$fontfamilyOswalds;
        font-weight: 500;
        font-size: 17px;
        line-height: 26px;
        color: v.$whiteColor;
      }
      &-money {
        max-width: 200px;
        width: 100%;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: rgba(v.$whiteColor, 0.6);
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-grow: 0;
      flex-shrink: 1;
      width: 100%;
      gap: 10px;
      .action-button {
        width: 100%;
        max-width: 140px;
      }
    }

    &__payout {
      font-family: v.$fontfamilyBokor;
      font-weight: 400;
      font-size: 17px;
      line-height: 20px;
      text-transform: capitalize;
      color: v.$bronzeColor;
      padding-right: 42px;
      position: relative;
      margin-left: 10px;
      cursor: pointer;
      &::before {
        content: '';
        width: 32px;
        height: 32px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%) rotate(-180deg);
        background: url('../../../../../../assets/icons/CaretUp.svg') center /
          cover no-repeat;
      }
    }
  }
  &_active {
    #{$tableRow}-item {
      &::after {
        border-left-color: $activeColor;
        // border-right: 10px solid $activeColor;
      }
      &::before {
        border-right-color: $activeColor;
      }
      &__inner {
        background: $activeColor;
      }
      &__payout {
        &::before {
          transform: translateY(-50%);
        }
      }
    }
    .subtable {
      display: block;
    }
  }
  @media (max-width: 1050px) {
    &-item {
      &__payout {
        font-size: 0;
        margin-left: 0;
      }
    }
  }
  @media (max-width: 920px) {
    &-item__content {
      gap: 0;
    }
  }
  @media (max-width: 830px) {
    &-item {
      &__buttons {
        button {
          display: none;
        }
      }
      &__payout {
        &::before {
          transform: translateY(-50%) rotate(-270deg);
        }
      }
    }
  }
}
