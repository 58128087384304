@use '../../../../variables.scss' as v;

.rhombus-button {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 222.22px;
  height: 80px;
  background: url('../../../../assets/buttons/rhomb-bg.png') center / cover
    no-repeat;
  font-family: v.$fontfamilyBokor;
  font-weight: 400;
  font-size: 22px;
  line-height: 22px;
  color: v.$buttonBlack;
  z-index: 2;
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 65%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 50px rgba(250, 185, 37, 0.7);
    border-radius: 50px;
    opacity: 0;
    transition: 0.3s;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
  }
}
