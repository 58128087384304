@use '../../../../../variables.scss' as v;
.card {
  position: relative;
  width: 518px;
  min-height: 497px;
  $card: &;
  &__inner {
    justify-content: flex-end;
    gap: 25px;
    z-index: -1;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 45px 50px 70px;
    position: relative;
    z-index: 3;
  }
  &__image {
    padding: 15px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    &_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }
  }
  @media (max-width: 830px) {
    &.unvisible {
      width: 100%;
      min-height: auto;
      max-width: 300px;
      text-align: center;
      border: 2px solid v.$bronzeColor;
      height: 52px;
      &::before {
        border: none;
      }
      #{$card}__inner {
        padding: 0;
      }
      #{$card}__image {
        display: none;
      }
      .staking-card-content,
      .percent-rate,
      .locked-info {
        display: none;
      }
      .staking-title {
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        color: v.$bronzeColor;
      }
      &.active {
        background: v.$bronzeColor;
        .staking-title {
          color: v.$buttonBlack;
        }
      }
    }
  }
  @media (max-width: 550px) {
    width: 100%;
    &__inner {
      padding: 120px 20px 40px 20px;
    }
  }
}
