@use '../../../../variables.scss' as v;

.action-button {
  font-family: v.$fontfamilyBokor;
  $actionButton: &;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  text-transform: capitalize;
  color: v.$goldColor;
  background: none;
  position: relative;
  height: 52px;
  padding-left: 11px;
  padding-right: 11px;
  &::before {
    content: '';
    display: block;
    border: 11px solid #000;
    border-image: url('../../../../assets/buttons/btn-gold.svg') 11 round round;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
  &.bronze {
    color: v.$bronzeColor;
    &::before {
      border-image: url('../../../../assets/buttons/btn-bronze.svg') 11 round
        round;
    }
    #{$actionButton}__inner {
      border-color: v.$bronzeColor;
    }
  }
  &_disabled {
    opacity: 0.3;
  }
}
