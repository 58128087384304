@use '../../../../../../variables.scss' as v;

.table-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 3;
  &__inner {
    position: relative;
    background: #0c0d13;
    padding: 28px 20px;
    border-radius: 20px 20px 0px 0px;
    height: 100%;
  }
  &__body {
    overflow-y: auto;
    height: 100%;
  }

  &__title {
    font-family: v.$fontfamilyBokor;
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
    color: v.$whiteColor;
    margin-bottom: 30px;
  }

  &__exit {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 20px;
    top: 28px;
    background: url('../../../../../../assets/icons/exit.svg') center / cover
      no-repeat;
  }
}
.table-popup-staked {
  &__head {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
    padding: 0 10px;
  }

  &__title {
    font-family: v.$fontfamilyOswalds;
    font-weight: 300;
    font-size: 17px;
    line-height: 20px;
    color: #b7b7b7;
    max-width: 100px;
    width: 100%;
  }

  &__body {
    background: rgba(0, 0, 0, 0.5);
    padding: 18px 10px;
    margin-bottom: 30px;
  }

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
    gap: 10px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
.table-popup-payouts {
  height: 100%;
  &__head {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    padding: 0 10px;
  }

  &__title {
    font-family: v.$fontfamilyOswalds;
    font-weight: 300;
    font-size: 17px;
    line-height: 20px;
    color: #b7b7b7;
    max-width: 100px;
    width: 100%;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-bottom: 40px;
  }
  &__row {
    display: flex;
    align-items: center;
    padding: 18px 10px;
    background: rgba(0, 0, 0, 0.5);
    gap: 10px;
    div {
      max-width: 100px;
      width: 100%;
    }
  }
}
.subrow {
  &__percent {
  }
}
