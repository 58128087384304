/* footer */
.footer { 
  padding: 72px 0 42px;
  font-weight: 300;
  font-size: 17px;
  line-height: 26px;
  color: $grayTexts;
  text-align: center;
  &__text {
    color: #6D6D70;
    margin-bottom: 26px;
    a {
      color: $grayTexts;
      border-bottom: 1px solid #6D6D70;
      margin: 0 5px;
      &:hover {
        color: $bronze_light;
        border-color: $bronze_light;
      }
    }
  }
  @media (max-width: $break_sm) {
    padding: 45px 0 60px;
    font-size: 12px;
    line-height: 18px;
  }
}
/* /footer */