/* title */
.title { 
  font-family: 'Bokor', Arial, sans-serif;
  font-weight: 400;
  font-size: 60px;
  line-height: 66px;
  color: $bronze_light;
  text-align: center;
  &:before {
    content: '';
    display: block;
    max-width: 377px;
    height: 58px;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    background-image: url(../../img/title/top.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
  &:after {
    content: '';
    display: block;
    max-width: 377px;
    height: 17px;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    background-image: url(../../img/title/bottom.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
  @media (max-width: $break_sm) {
    font-size: 50px;
    line-height: 55px;
    &:before {
      max-width: 315px;
    }
  }
}
/* /title */