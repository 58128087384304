@use "../../../variables.scss" as v;

.network-not-supported {
  height: 100vh;
  width: 100%;
  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 35px;
  }

  &__icon {
    width: 48.27px;
    height: 49.86px;
  }

  &__title {
    font-family: v.$fontfamilyBokor;
    font-weight: 400;
    font-size: 35px;
    line-height: 22px;
    color: v.$whiteColor;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 24px;
    color: #cecece;
  }
  .rhombus-button {
    position: static;
    transform: translate(0);
  }
}
