.cards {
  padding-bottom: 50px;
  padding-top: 220px;
  margin-bottom: 30px;
  width: 100%;
  position: relative;
  $cards: &;
  &__inner {
    max-width: 1115px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 1051px) {
    &__inner {
      gap: 10px;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  @media (max-width: 830px) {
    &.unvisible {
      margin-bottom: 0;
      #{$cards}__inner {
        align-items: flex-end;
        height: 1060px;
        justify-content: center;
        flex-wrap: nowrap;
      }
    }
  }
  @media (max-width: 550px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 210px;
    margin-bottom: 50px;
  }
}
