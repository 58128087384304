.border {
  padding: 15px;
  position: relative;
  cursor: pointer;
  &::before {
    content: '';
    display: block;
    border: 70px solid #000;
    border-image: url('../../../assets/borders/borders-card.png') 70 round round;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
  &.active {
    cursor: default;
    &::before {
      border-image: url('../../../assets/borders/borders-selected.png') 70 round
        round;
    }
  }
  // &:hover {
  //   &::before {
  //     border-image: url('../../../assets/borders/borders-hover.png') 70 round
  //       round;
  //   }
  // }
  &__inner {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.popup-border {
  padding: 10px;
  width: 100%;
  &::before {
    content: '';
    display: block;
    border: 40px solid #000;
    border-image: url('../../../assets/borders/popup-border.png') 40 round round;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
  .popup-border__inner {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
