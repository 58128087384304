@use '../../../../../variables.scss' as v;

.staking-table {
  &__inner {
  }

  &__head {
    display: flex;
    align-items: center;
    padding: 10px 25px;
    opacity: 0.5;
  }

  &__item {
    font-family: v.$fontfamilyOswalds;
    font-weight: 300;
    font-size: 17px;
    line-height: 26px;
    color: v.$whiteColor;
    max-width: 200px;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  @media (max-width: 1000px) {
    &__item {
      max-width: 160px;
    }
  }
  @media (max-width: 900px) {
    &__item {
      max-width: 120px;
    }
  }
}
