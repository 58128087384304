/* settings */
a {
  transition: 0.1s;
  text-decoration: none;
}
button,
input,
textarea {
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 16px;
}
body {
  min-width: 319px;
  background-color: #0c0d13;
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 16px;
  color: $white;
  &.lock {
    overflow: hidden;
  }
}
.mobile-hide {
  @media (max-width: $break_xs) {
    display: none !important;
  }
}
.mobile-visible {
  @media (min-width: $break_xs) {
    display: none !important;
  }
}
.tablet-hide {
  @media (max-width: $break_sm) {
    display: none !important;
  }
}

.tablet-visible {
  @media (min-width: $break_sm) {
    display: none !important;
  }
}
.row {
  @include row();
}
.wrapper {
  @include wrapper();
}
*:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #fff !important;
}
*::placeholder {
  color: #a9a9a9;
  opacity: 1;
}
.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.content {
  flex-grow: 1;
}
.navpage {
  padding: 100px 0;
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  ul {
    font-size: 18px;
    li {
      margin: 10px 0;
    }
  }
}
/* /settings */
