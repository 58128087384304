@use '../../../../../../../variables.scss' as v;

.staking-card-content {
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .action-button {
    width: 100%;
  }
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    height: 52px;
    &__head {
      display: flex;
      align-items: flex-end;
    }

    &__description {
      min-width: 125px;
      width: 100%;
    }

    &__title {
      font-family: v.$fontfamilyOswalds;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: v.$bronzeLightColor;
    }

    &__money {
      font-family: v.$fontfamilyOswalds;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      display: none;
      color: v.$whiteColor;
    }

    &__amount {
      font-family: v.$fontfamilyBokor;
      font-weight: 400;
      font-size: 20px;
      line-height: 18px;
      color: v.$whiteColor;
    }
    .action-button {
      width: 160px;
    }
  }
  @media (max-width: 550px) {
    &-item {
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }   
    .action-button {
      width: 100%;
    } 
  }
}
