@use '../../../../../../variables.scss' as v;

.table_date {
  font-family: v.$fontfamilyOswalds;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: v.$whiteColor;
  width: 200px;
  @media (max-width: 1000px) {
    width: 160px;
  }
  @media (max-width: 900px) {
    width: 120px;
  }
}

.amount {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 200px;

  &_number {
    font-family: v.$fontfamilyOswalds;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: v.$whiteColor;
  }
  &_money {
    max-width: 200px;
    width: 100%;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(v.$whiteColor, 0.6);
  }
  @media (max-width: 1000px) {
    width: 160px;
  }
  @media (max-width: 900px) {
    width: 120px;
  }
}
