.staking-page .staking {
  margin-bottom: 300px;
  &__inner {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: url('../../../assets/cards/fon.png') center / cover no-repeat;
      opacity: 0.2;
      z-index: -1;
    }
  }
}
