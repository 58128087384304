.popup {
  position: fixed;
  z-index: 5;
  width: 100%;
  height: 100vh;
  background: rgba(#000, 0.8);
  display: none;
  &_active {
    display: block;
  }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 540px;
    width: 100%;
  }
  &__exit {
    content: url('../../../assets/icons/exit.svg');
    position: absolute;
    top: 30px;
    right: 35px;
    z-index: 6;
    cursor: pointer;
  }
  @media (max-width: 600px) {
    &__inner {
      max-width: 390px;
      width: 100%;
    }
    &__exit {
      width: 40px;
      height: 40px;
      top: 28px;
      right: 19px;
    }
    &-border {
      width: 100%;
      padding: 0;
      &::before {
        z-index: -1;
        border: none;
      }
    }
  }
}
